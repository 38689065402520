import React from 'react';
import {graphql} from 'gatsby';

import {useLocale} from '@app/hooks';

import {Breadcrumb, Layout, Seo, Share} from '@app/components';

import logo from '@app/assets/images/vox-aesthetic-logo.png';

const Tag: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'pages.Tag',
  };
  const {t} = useLocale();

  return (
    <Layout>
      <Breadcrumb title={t('labels.metaTitle', SCOPE_OPTIONS)} />
      <Seo title={t('labels.metaTitle', SCOPE_OPTIONS)} />
      <div className="container mx-auto px-4 pt-20 pb-5 lg:px-0 xl:max-w-container-xl text-lg">
        <p className="text-center my-2.5">
          <img src={logo} alt="Vox Aesthetic" className="inline-block" />
        </p>
        <p className="text-center my-2.5">
          <strong className="font-bold">
            {t('labels.title', SCOPE_OPTIONS)}
          </strong>
        </p>
        <p className="text-center my-2.5">
          <strong className="font-bold">
            {t('labels.voxAestheticMagazine', SCOPE_OPTIONS)}
          </strong>
        </p>
        <p className="text-center my-2.5">
          {t('labels.publisher', SCOPE_OPTIONS)}
        </p>
        <p className="text-center my-2.5">Op. Dr. Serdar Bora Bayraktaroğlu</p>
        <p className="text-center my-2.5">info@clinicsbb.com</p>
        <p className="text-center my-2.5">
          <strong className="font-bold">
            {t('labels.generalCoordinator', SCOPE_OPTIONS)}
          </strong>
        </p>
        <p className="text-center my-2.5">Miray Özçiçek</p>
        <p className="text-center my-2.5">info@clinicsbb.com</p>
        <p className="text-center my-2.5">
          <strong className="font-bold">
            {t('labels.graphicDesign', SCOPE_OPTIONS)}
          </strong>
        </p>
        <p className="text-center my-2.5">Miray Özçiçek</p>
        {/* <p className="text-center my-2.5">
          <strong className="font-bold">
            {t('labels.contributors', SCOPE_OPTIONS)}
          </strong>
        </p>
        <p className="text-center my-2.5">Tuba Küçükaksu</p>
        <p className="text-center my-2.5">Samet Sezen</p>
        <p className="text-center my-2.5">Elvan Yarma</p>
        <p className="text-center my-2.5">Merih Tombul</p>
        <p className="text-center my-2.5">Gizem Yıldırım</p> */}
        <p className="text-center my-2.5">
          <strong className="font-bold">
            {t('labels.contact', SCOPE_OPTIONS)}
          </strong>
        </p>
        <p className="text-center my-2.5">Clinic SBB</p>
        <p className="text-center my-2.5">
          Harbiye mah. Maçka Cad. Erhan Apt. No:16 Kat: 2 D:5
        </p>
        <p className="text-center my-2.5">Nişantaşı/İSTANBUL</p>
        <p className="text-center my-2.5">
          <a href="mailto:info@clinicsbb.com">info@clinicsbb.com</a>
        </p>
        <div className="mt-5">
          <Share title={t('labels.metaTitle', SCOPE_OPTIONS)} />
        </div>
      </div>
    </Layout>
  );
};

export default Tag;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
